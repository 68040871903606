const SDS = {
  name: "SDS",
  to: "#",
  children: [
    { name: "SDS Home", to: "/" },
    {
      name: "SDS Request Form",
      to: "/MSDSRequestForm.aspx",
    },
    {
      name: "ELECTRONIC SDS DELIVERY",
      to: "/optin.aspx",
    },
  ],
};
const aboutMenu = {
  name: "About",
  to: "https://www.gp.com/about-us",
  children: [
    { name: "Learn more about GP", to: "https://www.gp.com/about-us" },
    {
      name: "Executive Leadership Team",
      to: "https://www.gp.com/about-us/executive-leadership-team",
    },
    {
      name: "Our History",
      to: "https://www.gp.com/about-us/executive-leadership-team",
    },
    {
      name: "Georgia-Pacific Locations",
      to: "https://www.gp.com/about-us/executive-leadership-team",
    },
    {
      name: "Media Library",
      to: "https://www.gp.com/about-us/executive-leadership-team",
    },
    {
      name: "Media Contacts",
      to: "https://www.gp.com/about-us/executive-leadership-team",
    },
    {
      name: "News & Stories",
      to: "https://www.gp.com/about-us/executive-leadership-team",
    },
  ],
};
const products = {
  name: "Products",
  to: "https://www.gp.com/about-us",

  children: [
    { name: "Product Overview", to: "https://www.gp.com/product-overview" },
    {
      to: "https://www.gp.com/product-overview/gp-packaging",
      name: "GP Packaging",
    },
    {
      to: "https://www.gp.com/product-overview/consumer-products",
      name: "Consumer Products",
    },
    {
      to: "https://www.gp.com/product-overview/gp-building-construction-products",
      name: "GP Building Products",
    },
    {
      to: "https://www.gp.com/product-overview/gp-pro",
      name: " GP Pro",
    },
    {
      to: "https://www.gp.com/product-overview/gp-cellulose",
      name: "GP Cellulose",
    },
    {
      to: "https://www.gp.com/product-overview/gp-chemicals",
      name: "Georgia-Pacific Chemicals",
    },
  ],
};
const sustainbility = {
  name: "Sustainbility",
  to: "#",
  children: [
    {
      name: "Sustainability at Georgia-Pacific",
      to: "https://www.gp.com/sustainability",
    },
    {
      name: "Stewardship in Action",
      to: "https://www.gp.com/stewardship",
    },
    {
      name: "Sustainability Tools",
      to: "https://www.gp.com/sustainability/sustainability-tools",
    },
    {
      name: "Certifications and Awards",
      to: "https://www.gp.com/sustainability/certificates-and-awards",
    },
  ],
};
const community = {
  name: "Community",
  to: "#",
  children: [
    { name: "Foundation", to: "https://www.gp.com/community" },
    {
      name: "Executive Leadership Team",
      to: "https://www.gp.com/community/areas-of-investment",
    },
    {
      name: "Bucket Brigade",
      to: "https://www.gp.com/community/bucket-brigade",
    },
    {
      name: "Foundation FAQs",
      to: "https://www.gp.com/community/foundation-faqs",
    },
  ],
};
const careers = {
  name: "Careers",
  to: "https://www.gp.com/about-us",
  children: [
    { name: "Careers at Georgia-Pacific", to: "https://www.gp.com/careers" },
    {
      name: "Engineering Jobs",
      to: "https://www.gp.com/careers/engineering-jobs",
    },
    {
      name: "Operations & Manufacturing Jobs",
      to: "https://www.gp.com/careers/manufacturing-operations-jobs",
    },
    {
      name: "Maintenance Jobs",
      to: "https://www.gp.com/careers/maintenance-jobs",
    },
    {
      name: "Supply Chain Jobs",
      to: "https://www.gp.com/careers/supply-chain-jobs",
    },
    {
      name: "Sales Jobs",
      to: "https://www.gp.com/careers/sales-jobs",
    },
    {
      name: "Accounting & Finance Jobs",
      to: "https://www.gp.com/careers/accounting-finance-jobs",
    },
    {
      name: "Environmental Health & Safety Jobs",
      to: "https://www.gp.com/careers/environmental-health-safety-jobs",
    },
    {
      name: "IT Jobs",
      to: "https://www.gp.com/careers/information-technology-jobs",
    },
    {
      name: "College Recruiting",
      to: "https://www.gp.com/careers/college-recruiting",
    },
    {
      name: "Benefits",
      to: "https://www.gp.com/careers/benefits",
    },
    {
      name: "Careers for Military Veterans",
      to: "https://www.gp.com/careers/veterans",
    },
  ],
};

export { aboutMenu, products, sustainbility, community, careers, SDS };
