import React, { useState } from "react";
import "./SDSMain.css";
import {
  styled,
  Box,
  Popper,
  Checkbox,
  TextField,
  Autocomplete,
  Divider,
  ClickAwayListener,
  AutocompleteCloseReason,
} from "@mui/material";
import CircularProgress from "@material-ui/core/CircularProgress";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function ProductSearchComponent(props) {
  const [checkAll, setCheckAll] = React.useState(true);
  const [open, setOpen] = React.useState(false);
  const checkAllChange = (event) => {
    setCheckAll(event.target.checked);
    if (event.target.checked) {
      props.setValue(props.productList);
    } else {
      props.setValue([]);
    }
  };
  const handleSearchClick = () => {
    let productTypes = props.value
      .map(function (e) {
        return e.lProductTypeId;
      })
      .join(", ");
    let sdsName = props.name;

    props.getSDSData(productTypes, sdsName, props.isEmergencyVendor);
  };
  const handleClickAway = (e) => {
    setOpen(false);
  };
  return (
    <>
      <div className="rowItem ">
        <ClickAwayListener onClickAway={handleClickAway}>
          <Box>
            <Autocomplete
              loading
              isOptionEqualToValue={(option, value) =>
                option.lProductTypeId === value.lProductTypeId
              }
              multiple
              disableCloseOnSelect
              id="checkboxes-tags-demo"
              options={props.productList}
              defaultValue={props.productList}
              value={props.value}
              open={open}
              onChange={(e, v, reason) => {
                props.setValue(v);

                if (reason === "clear" || reason == "removeOption") {
                  setCheckAll(false);
                }
              }}
              onClose={() => {
                setOpen(false);
              }}
              onOpen={() => {
                setOpen(true);
              }}
              PopperComponent={(param) => (
                <PopperStyledComponent {...param}>
                  <Box {...param} />
                  <Divider />
                  <Box
                    sx={{
                      backgroundColor: "white",
                      height: "45px",
                      textOverflow: "ellipsis",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                    }}
                  >
                    <Checkbox
                      checked={checkAll}
                      onChange={checkAllChange}
                      id="check-all"
                      sx={{ marginRight: "8px" }}
                      onMouseDown={(e) => e.preventDefault()}
                    />
                    Select All
                  </Box>
                </PopperStyledComponent>
              )}
              getOptionLabel={(option) => option.vsProductType}
              renderOption={(props, option, { selected }) => (
                <li {...props}>
                  <Checkbox
                    icon={icon}
                    checkedIcon={checkedIcon}
                    style={{ marginRight: 8 }}
                    checked={selected || checkAll}
                  />
                  {option.vsProductType}
                </li>
              )}
              style={{ width: "100%" }}
              renderInput={(params) => (
                <StyledTextBox
                  {...params}
                  label="PRODUCT TYPES"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <React.Fragment>
                        {props.loading ? (
                          <CircularProgress style={{ color: "#004d78" }} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </React.Fragment>
                    ),
                  }}
                />
              )}
            />
          </Box>
        </ClickAwayListener>
      </div>
      <div className=" rowItem productSearch">
        <input
          id="myInput"
          type="text"
          value={props.name}
          placeholder={"TYPE SDS NAME/PRODUCT NAME"}
          className="sdssearch-optin-input"
          onChange={(e) => props.setName(e.target.value)}
        />
        <button
          className="sdsbutton sdsbutton-optin-search "
          onClick={handleSearchClick}
        >
          Search
        </button>
      </div>
    </>
  );
}
const StyledTextBox = styled(TextField)`
  & .MuiOutlinedInput-notchedOutline {
    border-color: #004d78;
  }
  & :hover .MuiOutlinedInput {
    border-color: #004d78;
  }
  & .Mui-focused.MuiOutlinedInput {
    border-color: #004d78;
  }
  & .MuiInputLabel-outlined {
    color: black;
    font-weight: bold;
  }
  & :hover .MuiInputLabel-outlined {
    color: black;
  }
  & .MuiInputLabel-outlined .Mui-focused {
    color: black;
  }
  & .MuiOutlinedInput-input {
    font: 78% Helvetica, Arial, sans-serif;
  }
`;
const PopperStyledComponent = styled(Popper)(({ theme }) => ({
  border: `1px solid ${
    -theme.palette.mode === "light"
      ? "rgba(149, 157, 165, 0.2)"
      : "rgb(1, 4, 9)"
  }`,
}));
