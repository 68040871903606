import React, { useState } from "react";
import "./Header.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserCircle, faBriefcase } from "@fortawesome/free-solid-svg-icons";

import {
  aboutMenu,
  products,
  sustainbility,
  community,
  careers,
  SDS,
} from "./HeaderConfig";
import Search from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import SDSMenu from "../Menu/Menu";
import SDSMenuLink from "../Menu/MenuLinks";

function Header() {
  const [hamburgerMenu, setHamburgerMenu] = useState(false);
  const [isSearch, setSearch] = useState(false);
  const handleClick = () => setHamburgerMenu(!hamburgerMenu);
  return (
    <section className="top">
      <header className="header">
        <nav className="nav-util">
          <a
            href="http://mygp.gp.com/"
            target={"_blank"}
            className="nav-util__link menu-item menu-item-type-custom menu-item-object-custom menu-item-181"
          >
            <p className="login">
              <FontAwesomeIcon icon={faBriefcase} />
              <span>employee login</span>
            </p>
          </a>

          <a
            href="https://login.gp.com/Partner"
            target={"_blank"}
            className="nav-util__link menu-item menu-item-type-custom menu-item-object-custom menu-item-182"
          >
            <p className="login">
              <FontAwesomeIcon icon={faUserCircle} />
              <span>partner login</span>
            </p>
          </a>
        </nav>
        <a className="header__logo" href="https://www.gp.com/">
          <img
            className="lazyloaded"
            src="/images/GP-Main-Logo.png"
            alt="Georgia-Pacific"
          ></img>
        </a>
        <nav className={hamburgerMenu ? "nav is-open" : "nav "}>
          <button
            onClick={handleClick}
            className="nav__toggle"
            title="Menu"
            style={
              hamburgerMenu
                ? {
                    backgroundImage: "url(/images/icon-close.svg)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "18px 18px",
                    backgroundColor: "white",
                  }
                : {
                    backgroundImage: "url(/images/gp-hamburger.svg)",
                    backgroundRepeat: "no-repeat",
                    backgroundPosition: "center",
                    backgroundSize: "18px 18px",
                    backgroundColor: "white",
                  }
            }
          >
            <i className={hamburgerMenu ? "fas " : "fas"} />
          </button>

          <ul className={"nav-primary is-open"}>
            <SDSMenuLink menuObj={SDS}></SDSMenuLink>
            <SDSMenu menuObj={aboutMenu}></SDSMenu>
            <SDSMenu menuObj={products}></SDSMenu>
            <SDSMenu menuObj={sustainbility}></SDSMenu>
            <SDSMenu menuObj={community}></SDSMenu>
            <SDSMenu menuObj={careers}></SDSMenu>
            {/* <li className="nav-primary__item">
              <IconButton size="medium" className=" searchButton">
                <Search></Search>
              </IconButton>
            </li> */}
          </ul>
        </nav>
      </header>
    </section>
  );
}

export default Header;
