import * as yup from "yup";
const initialValues = {
  companyName: "",
  firstName: "",
  lastName: "",
  emailAddress: "",
  streetAddress: "",
  city: "",
  state: "",
  postalCode: "",
  country: "United States",
  telephone: "",
  sdsName: "",
  otherComments: "",
};

const formAttributes = [
  {
    name: "companyName",
    label: "Company Name",
    type: "text",
    placeholder: "Company Name",
  },
  {
    name: "firstName",
    label: "First Name",
    type: "text",
    placeholder: "First Name",
  },
  {
    name: "lastName",
    label: "Last Name",
    type: "text",
    placeholder: "Last Name",
  },
  {
    name: "emailAddress",
    label: "Email Address",
    type: "text",
    placeholder: "Email Address",
  },
  {
    name: "streetAddress",
    label: "Street Address:(no PO Boxes)",
    type: "text",
    placeholder: "Street Address:(no PO Boxes)",
  },
  {
    name: "city",
    label: "City",
    type: "text",
    placeholder: "City",
  },
  {
    name: "state",
    label: "State/Province",
    type: "select",
    options: "",
    placeholder: "State/Province",
  },
  {
    name: "postalCode",
    label: "Zip or Postal Code",
    type: "text",
    placeholder: "Zip or Postal Code",
  },
  {
    name: "country",
    label: "Country",
    type: "select",
    placeholder: "Country",
  },
  {
    name: "telephone",
    label: "Telephone",
    type: "text",
    placeholder: "Telephone",
  },
  {
    name: "sdsName",
    label: "Please type the name of the SDS you need",
    type: "textarea",
    placeholder: "Please type the name of the SDS you need",
  },
  {
    name: "otherComments",
    label: "Other Comments",
    type: "textarea",
    placeholder: "Other Comments",
  },
];

const phoneRegExp =
  "/^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/";

const postalReg = "(^[a-zA-Z0-9]+([- \\s]{1}[a-zA-Z0-9]+)*$)";
const phoneReg = "^[1-9][0-9]*$";
const validationSchema = yup.object({
  companyName: yup
    .string("Enter Company Name")
    .min(2, "Too Short!")
    .max(30, "Company field should not exceed more than 30 characters.")
    .required("Company Name is required."),
  firstName: yup
    .string("Enter First Name")
    .min(2, "Too Short!")
    .max(
      30,
      "First Name field should not exceed more than 30 characters and have special characters."
    )
    .required("First Name is required."),
  lastName: yup
    .string()
    .min(2, "Too Short!")
    .max(
      30,
      "Last Name field should not exceed more than 30 characters and have special characters."
    )
    .required("Last Name is required."),
  emailAddress: yup
    .string()
    .email("Enter valid Email Address.")
    .required("Email is required."),
  streetAddress: yup
    .string()
    .max(
      250,
      "Address Line 1 field should not exceed more than 250 characters and can have special characters "
    )
    .required("Address is required."),
  city: yup
    .string("Enter City ")
    .max(
      60,
      "City field should not exceed more than 60 characters and have special characters."
    )
    .required("City  is required."),
  state: yup.string().required("State is required."),
  postalCode: yup
    .string()
    .matches(postalReg, "Postal code is not valid.")
    .min(4, "Minimum of 4 digit Zip Code is required.")
    .max(10, "Maximum length exceeded 10 digit Zip Code.")
    .required("Zip Code is required."),

  telephone: yup
    .string()
    .notRequired()
    .max(
      20,
      "Telephone field shall be of 20 digits and should not start with 0."
    ),
  sdsName: yup.string().required("SDS name is required."),
  country: yup.string().required("Country is required."),
});
export { formAttributes, initialValues, validationSchema };
