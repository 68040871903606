import React, { useCallback, useEffect, useState } from "react";
import "./SDSMain.css";
import ProductSearchComponent from "./ProductSearch.js";
import SDSGrid from "./SDSGrid.js";
import SDSTitle from "../SDSTitle/SDSTitle.js";
import Loader from "../Spinner/Spinner";
import { NavLink, useParams, useSearchParams } from "react-router-dom";
import axios from "axios";
import toast from "react-hot-toast";
import ErrorModal from "../SDSForm/ErrorModal";

export default function SDSMain() {
  let [searchParams] = useSearchParams();

  const [isEmergencyVendor, setIsEmergencyVendor] = useState(false);
  const [productList, setproductList] = useState([]);
  const [isPageLoading, setPageLoading] = useState(true);
  const [loadingGrid, setLoadingGrid] = useState(true);
  const [value, setValue] = React.useState([]);
  const [name, setName] = useState("");
  const [tableData, setTableData] = React.useState([]);
  const [openErrorModel, setOpenErrorModel] = React.useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorFetchedChecker, setErrorFetchedChecker] = useState(false);

  const poppupCloseCallBack = () => {
    setErrorMessage("");
  };
  const popupRetryCallBack = () => {
    setPageLoading(true);
    setErrorFetchedChecker((c) => !c);
  };
  const timeOut = process.env.REACT_APP_TIMEOUT;
  const baseAPIURL = process.env.REACT_APP_API_BASEURL;
  const currEnv = process.env.REACT_APP_ENVIRONMENT;
  const getSDSData = useCallback((productTypes, sdsName, isEmergencyVendor) => {
    setLoadingGrid(true);

    let baseURL = `${baseAPIURL}Product/GetMSDSProducts`;
    if (productTypes === undefined && sdsName === undefined) {
      baseURL += "?isEmergencyVendor=" + isEmergencyVendor;
    } else {
      if (productTypes !== undefined) baseURL += "?Ptype=" + productTypes;
      if (sdsName !== undefined) baseURL += "&Pname=" + sdsName;
      baseURL += "&isEmergencyVendor=" + isEmergencyVendor;
    }

    return axios({
      method: "GET",
      url: baseURL,
      withCredentials: false,
      timeout: timeOut,
    })
      .then((res) => {
        if (sdsName != undefined) setTableData(res.data);
        setLoadingGrid(false);
        return res;
      })
      .catch((err) => {
        setLoadingGrid(false);
        setTableData([]);
      });
  }, []);

  const GetProductType = () => {
    return axios({
      method: "GET",
      url: `${baseAPIURL}Product/GetProductTypes`,
      withCredentials: false,
      timeout: timeOut,
    });
  };
  useEffect(() => {
    const abortController = new AbortController();
    let isEmergencyVendor = false;
    for (const entry of searchParams.entries()) {
      if (entry[0] === "isEmergencyVendor") {
        if (entry[1].toUpperCase() === "TRUE") {
          isEmergencyVendor = true;
        }
      }
    }

    setIsEmergencyVendor(isEmergencyVendor);

    Promise.all([
      GetProductType(),
      getSDSData(undefined, undefined, isEmergencyVendor),
    ])
      .then((values) => {
        setproductList(values[0].data);
        setValue(values[0].data);
        setTableData(values[1].data);
        setPageLoading(false);
      })
      .catch((errors) => {
        setTableData([]);
        setPageLoading(false);

        if (errors.code === "ECONNABORTED") {
          setErrorMessage(
            "Looks like the server is taking too long to respond, please try again in sometime"
          );
          setOpenErrorModel(true);
        } else {
          toast.error("An Error occured while fetching the data.");
        }
      });
    return () => {
      abortController.abort();
    };
  }, [errorFetchedChecker]);

  return (
    <div>
      {isPageLoading ? (
        <Loader />
      ) : (
        <div id="outerWrapper">
          <div id="wrapper">
            <SDSTitle></SDSTitle>
            <div className="sectionitem">
              <div className="qfitem">
                <p className="maincontent">
                  The following SDSs are ready to download.To request Safety
                  Data Sheets for other products, or if you are unsure which
                  sheet you need, please use the{" "}
                  <NavLink
                    className={"sdsrequestFromLink"}
                    to={"/MSDSRequestForm.aspx"}
                  >
                    SDS Request Form
                  </NavLink>
                  . Requests are processed within one business day.
                  <a
                    className={"sdsrequestFromLink"}
                    href="mailto:GPChemical@gapac.com"
                  ></a>
                  If you would like to recieve all future SDSs via email rather
                  than paper copy.please visit the following website and
                  complete the required 'Opt' in process for{" "}
                  <NavLink className={"sdsrequestFromLink"} to={"/optin.aspx"}>
                    Electronic SDS Delivery
                  </NavLink>{" "}
                  using one of the unique customer code as shown on the SDS
                  cover letter which you receive via email.
                </p>
                <ProductSearchComponent
                  value={value}
                  productList={productList}
                  setValue={setValue}
                  setName={setName}
                  name={name}
                  isEmergencyVendor={isEmergencyVendor}
                  getSDSData={getSDSData}
                ></ProductSearchComponent>
                <SDSGrid
                  tableData={tableData}
                  loadingGrid={loadingGrid}
                ></SDSGrid>
              </div>
            </div>
          </div>
        </div>
      )}
      {openErrorModel && (
        <ErrorModal
          setOpenErrorModel={setOpenErrorModel}
          ErrorTitle={"Error"}
          Errormessage={errorMessage}
          closeCallback={poppupCloseCallBack}
          reTryCallBack={popupRetryCallBack}
          HideCloseButton={true}
        />
      )}
    </div>
  );
}
