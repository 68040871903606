import { React, useState, useEffect } from "react";
import "./CustomerDetails.css";
import "./OptIn.css";
import EditableTable1 from "./EditableTable1";
import EmailGrid from "./EmailGrid";
import Loader from "../Spinner/Spinner";
import axios from "axios";
import toast from "react-hot-toast";
import { useCallback } from "react";
export default function CustomerDetails(props) {
  const [customerOptIn, setCustomerOptIn] = useState({});
  const [customerEmail, setCustomerEmail] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // const currEnv = process.env.REACT_APP_ENVIRONMENT;

  const baseURL = process.env.REACT_APP_API_BASEURL;
  const btnOptOut_Click = () => {
    UpdateOptin(false);
  };
  const axiosConfig = {
    headers: {
      "Content-Type": "application/json;charset=UTF-8",
    },
  };
  const UpdateOptin = (IsOpt, val) => {
    customerOptIn.bElectronicOptIn = IsOpt;
    axios({
      method: "POST",
      url: baseURL + "OptIn/OptinUpdateDetails",
      withCredentials: false,
      data: {
        Emails: val ? val : [],
        AccessCode: props.customerCode,

        IsOpt: IsOpt ? true : false,
      },
      timeout: 30000,
      axiosConfig,
    })
      .then((res) => {
        if (res.status == 200 && IsOpt) {
          setCustomerEmail(res.data);

          toast.success("Email confirmation sent successfully.");
        } else if (res.status == 200 && !IsOpt) {
          props.setIsShown(true);
          toast.success("Opted Out successfully.");
        }
      })
      .catch((err) => {
        toast.error(err.response.data);
      });
  };
  useEffect(() => {
    const abortController = new AbortController();
    GetCustomerOptIn(props.customerCode);
    return () => {
      abortController.abort();
    };
  }, []);
  const GetCustomerOptIn = useCallback((customerCode) => {
    if (customerCode.trim().length == 0) {
      toast.error("Customer code is mandatory.");
      props.setIsShown(true);
      setIsLoading(false);
    } else {
      axios({
        method: "GET",
        url: baseURL + "OptIn/GetCustomerOptIn?AccessCode=" + customerCode,
        withCredentials: false,
        timeout: 30000,
      })
        .then((res) => {
          if (res.status == 200) {
            setCustomerOptIn(res.data);
            setCustomerEmail(res.data.emails);
            if (Object.keys(res.data).length !== 0) {
              props.setIsShown(false);
            } else {
              toast.error("Customer code not found.");
            }
          } else {
            toast.error("Error occured, please try with another code.");
          }

          setIsLoading(false);
        })
        .catch((err) => {
          if (err.message == "Request failed with status code 500") {
            toast.error("Customer code not found.");
          } else {
            // toast.error("Error occured, Please try again.");
          }
          props.setIsShown(true);
          setIsLoading(false);

          /* for anila this code will never execure bcoz err object doesn't contain response object */
          if (err.response.status == 400) {
            toast.error(err.response.data);
            setCustomerOptIn({});
          }
        });
      try {
      } catch (err) {}
    }
  }, []);
  return (
    <div>
      {Object.keys(customerOptIn).length !== 0 && !isLoading ? (
        <div>
          <h4 className="nameheader" id="txtCustomerName">
            {customerOptIn.vsCustomerName}
          </h4>
          <input
            type="hidden"
            id="custId"
            name="custId"
            value={customerOptIn.lCustomerId}
          />
          <div id="OptInOption">
            <p>
              By requesting the addition of your email below, I consent to
              receiving all future Safety Data Sheets (“SDSs”) from
              Georgia-Pacific LLC and its affiliated companies (collectively,
              “GP”) electronically at the e-mail address(es) provided. I
              understand that this electronic transmittal will the exclusive
              means by which GP will supply me and my employer with SDSs in
              accordance with 29 CFR § 1910.1200(g)(6). I understand that I may
              opt out of electronic transmittal of SDSs at any time by following
              the instructions provided herein or otherwise contacting GP at
              MSDSREQ@GAPAC.com or by calling 404-652-5119. By providing the
              foregoing consent, I confirm that the e-mail address(es) provided
              is valid and accessible by me. Further, I confirm that I have the
              necessary authority to provide this consent on my employer’s
              behalf.
            </p>
          </div>
          {customerOptIn.bElectronicOptIn && (
            <div id="OptOutOption">
              <div>
                <h3 title="ADD/UPDATE" className="h3" id="headerUpdateEmail">
                  ADD/UPDATE EMAIL
                </h3>
              </div>
              <p>
                After updating, please check your inbox for a verification
                email. For each email address updated, you will need to click on
                the link to activate At least one activated email is required.
              </p>

              <EditableTable1
                AddEmailName="Add Additional Email"
                width="20%"
                UpdateOptin={UpdateOptin}
              ></EditableTable1>

              <EmailGrid
                CustomerEmails={customerEmail}
                setCustomerEmail={setCustomerEmail}
                CustomerCode={props.customerCode}
              ></EmailGrid>

              <div className="optOut">
                <p>
                  To discontinue electronic delivery and receive paper SDSs,
                  please click the button Opt Out:
                </p>
                <div>
                  <button
                    className="sdsbutton sdsbutton-optin-search"
                    onClick={btnOptOut_Click}
                  >
                    OPT OUT
                  </button>
                </div>
              </div>
              <div>
                <div>&nbsp;</div>
              </div>
            </div>
          )}
          {!customerOptIn.bElectronicOptIn && (
            <div id="EmailCapture">
              <div>
                Enter the email address(es) where you would like to receive the
                SDSs.
                <div>&nbsp;</div>
                Check your inbox for a verification email. For each mail address
                entered, you will need to click on the link to activate.
                <div>&nbsp;</div>
                At least one activated email is required.
              </div>

              <div>
                <EditableTable1
                  AddEmailName="Add Email"
                  width="20%"
                  UpdateOptin={UpdateOptin}
                ></EditableTable1>
                <br />
              </div>
            </div>
          )}
        </div>
      ) : (
        <Loader />
      )}
    </div>
  );
}
