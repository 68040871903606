import React from "react";
import Footer from "../components/Footer/Footer";
import Header from "../components/Header/Header";
import { Toaster } from "react-hot-toast";
function Home({ children }) {
  return (
    <>
      <Header />
      <Toaster position="bottom-left"></Toaster>

      {children}
      <Footer />
    </>
  );
}

export default Home;
