/*   "start:dev": " env-cmd -f .env.dev react-scripts start",
 */

import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import SDSRequestFrom from "./components/SDSForm/SDSRequestFrom";
import SDSMain from "./components/SDSProduct/SDSMain";
import OptIn from "./components/SDSOptIn/OptIn";
import Activation from "./components/SDSActivation/Activation";

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import Home from "./Pages/Home";
import DirectDownload from "./Pages/DirectDownload";
function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route
            path="/"
            element={<Home children={<SDSMain></SDSMain>} />}
          ></Route>
          <Route
            path="/msdssearch.aspx"
            element={<Home children={<SDSMain></SDSMain>} />}
          ></Route>
          <Route
            path="/LatestMSDS.aspx"
            element={<DirectDownload></DirectDownload>}
          ></Route>

          <Route
            path="/MSDSRequestForm.aspx"
            element={<Home children={<SDSRequestFrom></SDSRequestFrom>} />}
          ></Route>

          <Route
            path="/optin.aspx"
            element={<Home children={<OptIn></OptIn>} />}
          ></Route>
          <Route
            path="/Activation.aspx"
            element={<Home children={<Activation></Activation>} />}
          ></Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
