import React, { useState } from "react";
import CustomerSearch from "./CustomerSearch";
import SDSTitle from "../SDSTitle/SDSTitle.js";
import "./OptIn.css";

export default function ProductSearchComponent(props) {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <div className="mainOptIn">
      <div id="wrapper">
        <SDSTitle></SDSTitle>
        <div className="sectionitem qfitem">
          <div className="section">
            <CustomerSearch></CustomerSearch>
          </div>
        </div>
      </div>
    </div>
  );
}
