import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import ErrorIcon from "@mui/icons-material/Error";
import "./Modal.css";

function ErrorModal({
  setOpenErrorModel,

  Errormessage,
  ErrorTitle,
  closeCallback,
  reTryCallBack,
  HideCloseButton,
}) {
  //const Errormessage = props.Errormessage;
  //const ErrorTitle = props.ErrorTitle;

  const handleClickOpen = () => {
    setOpenErrorModel(false);
  };

  const handleClose = () => {
    setOpenErrorModel(false);

    if (closeCallback) closeCallback();
  };
  const handleRetry = () => {
    setOpenErrorModel(false);

    if (reTryCallBack) reTryCallBack();
  };
  return (
    <div>
      <Dialog open={true} fullWidth maxWidth="md">
        <DialogTitle id="dialog-title-error" className="errortitle">
          {ErrorTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <br />
            {Errormessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          {!HideCloseButton && (
            <button
              className="sdsbutton  "
              variant="contained"
              onClick={handleClose}
              autoFocus
            >
              Close
            </button>
          )}
          <button
            className="sdsbutton  "
            variant="contained"
            onClick={handleRetry}
            autoFocus
          >
            Retry
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
ErrorModal.defaultProps = {
  ErrorTitle: "An error occurred",
  Errormessage: "Error message <validation messages>",
};

export default ErrorModal;
