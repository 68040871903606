import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import axios from "axios";

const authRequestOptions = {
  method: "POST",
  headers: { "Content-Type": "application/json" },
  body: JSON.stringify({
    ClientID: "5eadc9a8-344c-4a49-b553-7125f79197f4",
    Secret: "ᾎ⺲葉艹ꄺ๶서퓯閠問ꀐ엗콚ᕈ䋇꟭㼥풸饞ꫲꥵ",
  }),
};
const baseURL = process.env.REACT_APP_API_BASEURL;
axios.interceptors.request.use(
  (request) => {
    let now = new Date();

    const token = localStorage.getItem("accesstoken");

    if (
      token &&
      now.getTime() < new Date(localStorage.getItem("expiresin")).getTime()
    ) {
      request.headers["authorizationToken"] = token;
      return request;
    } else {
      localStorage.clear();

      return fetch(`${baseURL}Auth/AccessToken`, authRequestOptions)
        .then((response) => {
          if (response.status === 200) return response.json();
        })
        .then((res) => {
          var tokenExp = new Date();
          localStorage.setItem("accesstoken", res.token);
          tokenExp.setSeconds(tokenExp.getSeconds() + res.expiresIn - 300);
          localStorage.setItem("expiresin", tokenExp);
        })
        .then(() => {
          request.headers["authorizationToken"] =
            localStorage.getItem("accesstoken");
          return request;
        });
    }
  },
  (error) => {
    Promise.reject(error);
  }
);

import "./fonts/Helvetica/Helvetica.ttf";

import "./fonts/Verdana/verdana.ttf";
ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);
