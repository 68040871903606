import React from "react";
import "./Footer.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  faFacebookSquare,
  faLinkedin,
  faTwitterSquare,
  faYoutubeSquare,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";
function Footer() {
  return (
    <nav className="footer">
      <div className="panel">
        <div className="leftpanel">
          <a
            id="menu-item-184"
            className="footer-nav__link menu-item"
            title="News &amp; Updates"
            href="https://www.gp.com/news"
          >
            News &amp; Updates
          </a>
          <a
            id="menu-item-185"
            className="footer-nav__link menu menu-item"
            title="Careers"
            href="https://www.gp.com/careers"
          >
            Careers
          </a>
          <a
            id="menu-item-196"
            className="footer-nav__link"
            title="Legal Notices"
            href="https://www.gp.com/legal/legal-notices"
          >
            Legal Notices
          </a>
          <a
            id="menu-item-197"
            className="footer-nav__link"
            title="Privacy Policy"
            href="https://www.gp.com/legal/legal-notices/privacy-notice"
          >
            Privacy Notice
          </a>
          <a
            id="menu-item-198"
            className="footer-nav__link"
            title="Code of Conduct"
            target="_blank"
            href="https://codeofconduct.kochind.com/"
          >
            Code of Conduct
          </a>
        </div>
        <div className="rightpanel">
          <a
            href="https://www.facebook.com/GeorgiaPacific"
            target={"_blank"}
            className="icon__button"
          >
            <FontAwesomeIcon
              className="iconModification"
              icon={faFacebookSquare}
            />
          </a>
          <a
            href="https://www.instagram.com/georgiapacific/"
            target={"_blank"}
            className="icon__button"
          >
            <FontAwesomeIcon className="iconModification" icon={faInstagram} />
          </a>

          <a
            href="https://www.linkedin.com/company/georgia-pacific-llc"
            target={"_blank"}
            className="icon__button"
          >
            <FontAwesomeIcon className="iconModification" icon={faLinkedin} />
          </a>

          <a
            href="https://twitter.com/georgiapacific"
            target={"_blank"}
            className="icon__button"
          >
            <FontAwesomeIcon
              className="iconModification"
              icon={faTwitterSquare}
            />
          </a>
          <a
            className="icon__button"
            href="https://www.youtube.com/user/georgiapacifictv"
            target={"_blank"}
          >
            <FontAwesomeIcon
              className="iconModification"
              icon={faYoutubeSquare}
            />
          </a>
        </div>
      </div>
      <div className="rights">©2022 Georgia-Pacific. All rights reserved.</div>
    </nav>
  );
}

export default Footer;
