import { Form, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { TextField, Stack } from "@mui/material";
import Autocomplete from "@mui/material/Autocomplete";
import styled from "styled-components";

import { initialValues, validationSchema } from "./fromAttributeConfig";
import { Typography } from "@material-ui/core";
import { useState } from "react";
import axios from "axios";

import "./From.css";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";
import Loader from "../Spinner/Spinner";
function SDSRequestFrom() {
  const [error, setError] = useState("");

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [data, setData] = useState([]);

  const [openSuccessModel, setOpenSuccessModel] = useState(false);
  const [openErrorModel, setOpenErrorModel] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const defaultCountryValue = {
    id: "42",
    value: "USA",
    text: "United States",
  };

  const gobacktoHome = () => {
    poppupCloseCallBack();
    navigate("/");
  };

  const poppupCloseCallBack = () => {
    setErrorMessage("");
  };
  const baseURL = process.env.REACT_APP_API_BASEURL;
  const dataURL = `${baseURL}RequestForm/GetCountryState`;

  const getStatesforCountries = (data, country) => {
    setStates([]);

    let filteredData = data.filter((item) => {
      return item.countryName === country;
    });

    let sdata = [];
    let sinfo = [];
    filteredData.forEach((item) => {
      if (sinfo.indexOf(item.stateCode) === -1) {
        sdata.push({
          id: item.stateId,
          value: item.stateCode,
          text: item.stateName,
        });
      }
      sinfo.push(item.stateCode);
    });

    setStates(sdata);
  };

  useEffect(() => {
    let cdata = [];
    let sdata = [];
    let cinfo = [];
    let sinfo = [];

    const getCountryState = () => {
      axios({
        method: "GET",
        url: dataURL,
        withCredentials: false,
      })
        .then((res) => {
          setData(res.data);
          getStatesforCountries(res.data, "United States");
          res.data.forEach((item) => {
            if (cinfo.indexOf(item.countryCode) === -1) {
              cdata.push({
                id: item.countryId,
                value: item.countryCode,
                text: item.countryName,
              });
            }
            cinfo.push(item.countryCode);
            if (item.stateCode !== "NON") {
              if (sinfo.indexOf(item.stateCode) === -1) {
                sdata.push({
                  id: item.stateId,
                  value: item.stateCode,
                  text: item.stateName,
                });
              }
            }
            sinfo.push(item.stateCode);
          });

          setCountries(cdata);
        })
        .catch((err) => {});
    };
    getCountryState();
  }, []);

  const formik = useFormik({
    initialValues: initialValues,
    validationSchema: validationSchema,
    enableReinitialize: true,

    onSubmit: async (values, onsubmitProps) => {
      setIsLoading(true);
      let axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "*",
        },
      };
      grecaptcha.ready(function () {
        grecaptcha
          .execute("6LfUIVkgAAAAACNVDmldryGhTeeJGnMJhhUe6Z-Z", {
            action: "submit",
          })
          .then(function (token) {
            const submitData = () => {
              const data = {
                Company: values.companyName,
                EmailAddress: values.emailAddress,
                FirstName: values.firstName,
                LastName: values.lastName,
                AddressLine1: values.streetAddress,
                AddressLine2: "",
                City: values.city,
                State: values.state,
                Country: values.country,
                Zip: parseInt(values.postalCode),
                Telephone: parseInt(values.telephone),
                SdsName: values.sdsName,
                Comment: values.otherComments,
                IsCaptchaValidated: true,
              };

              axios({
                method: "POST",
                url: `${baseURL}RequestForm/SubmitRequestForm`,

                axiosConfig,
                data: data,
                timeout: 30000,
              })
                .then((res) => {
                  res.status;

                  setIsLoading(false);
                  onsubmitProps.setSubmitting(false);
                  toast.success("your request has been Submitted.");
                  gobacktoHome();
                })
                .catch((err) => {
                  let errors = [];
                  setIsLoading(false);
                  let data = err.response.data;
                  errors = data.errors;
                  let er = "";
                  try {
                    Object.entries(errors).forEach((entry) => {
                      const [key, value] = entry;
                      er = er + value + "\n";
                    });
                    setIsLoading(false);
                    setErrorMessage(er);
                    onsubmitProps.setSubmitting(false);
                    setOpenErrorModel(true);
                  } catch (err) {
                    setIsLoading(false);
                    setErrorMessage("Error occured please try again.");
                    onsubmitProps.setSubmitting(false);
                    setOpenErrorModel(true);
                  }
                });
            };
            axios({
              method: "POST",
              url: `${baseURL}RequestForm/GetReCaptchaToken?capchaDetails=${token}`,
              axiosConfig,
              timeout: 30000,
            })
              .then((res) => {
                const { success, score } = res.data;
                if (success) {
                  if (score >= 0.6) {
                    submitData();
                  }
                }
              })
              .catch((err) => {
                setErrorMessage("Error occurred please try again.");
                onsubmitProps.setSubmitting(false);
                setIsLoading(false);
                setOpenErrorModel(true);
              });
          });
      });
    },
  });
  const { setFieldValue, getFieldProps, values } = formik;

  const normalizeInput = (value, previousValue) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;

    if (!previousValue || value.length > previousValue.length) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
      return `(${currentValue.slice(0, 3)}) ${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };
  return (
    <div className="dsform">
      {isLoading ? (
        <Loader />
      ) : (
        <div className="sdsForm">
          <Typography align="center">
            Fill in the form below to request Safety Data Sheets (SDS). Your
            request will be processed within one business day.
          </Typography>
          <div className="requestForm">
            <FormikProvider value={formik}>
              {error ? <Error>{error}</Error> : null}
              <Form
                onSubmit={formik.handleSubmit}
                autoComplete="off"
                noValidate
              >
                <Stack className="mainform">
                  <Stack className="leftForm">
                    <TextField
                      autoComplete="CompanyName"
                      type={"text"}
                      label={"Company Name"}
                      {...formik.getFieldProps("companyName")}
                      error={
                        formik.touched.companyName &&
                        Boolean(formik.errors.companyName)
                      }
                      helperText={
                        formik.touched.companyName && formik.errors.companyName
                      }
                      className="formAttribute"
                    ></TextField>
                    <TextField
                      onChange={(event) => {}}
                      className="formAttribute"
                      autoComplete="firstName"
                      type={"text"}
                      label={"First Name"}
                      {...formik.getFieldProps("firstName")}
                      error={
                        formik.touched.firstName &&
                        Boolean(formik.errors.firstName)
                      }
                      helperText={
                        formik.touched.firstName && formik.errors.firstName
                      }
                    ></TextField>
                    <TextField
                      className="formAttribute"
                      autoComplete="lastName"
                      type={"text"}
                      label={"Last Name"}
                      {...formik.getFieldProps("lastName")}
                      error={
                        formik.touched.lastName &&
                        Boolean(formik.errors.lastName)
                      }
                      helperText={
                        formik.touched.lastName && formik.errors.lastName
                      }
                    ></TextField>
                    <TextField
                      className="formAttribute"
                      autoComplete="emailAddress"
                      type={"text"}
                      label={"Email"}
                      {...formik.getFieldProps("emailAddress")}
                      error={
                        formik.touched.emailAddress &&
                        Boolean(formik.errors.emailAddress)
                      }
                      helperText={
                        formik.touched.emailAddress &&
                        formik.errors.emailAddress
                      }
                    ></TextField>
                    <TextField
                      className="formAttribute"
                      autoComplete="telephone"
                      type={"text"}
                      label={"Telephone"}
                      inputProps={{ maxLength: 20 }}
                      {...formik.getFieldProps("telephone")}
                      error={
                        formik.touched.telephone &&
                        Boolean(formik.errors.telephone)
                      }
                      helperText={
                        formik.touched.telephone && formik.errors.telephone
                      }
                    ></TextField>
                  </Stack>
                  <Stack className="rightForm">
                    <Autocomplete
                      disabled={!countries.length}
                      disableClearable
                      id="combo-box-countries"
                      defaultValue={defaultCountryValue}
                      options={countries}
                      autoComplete={false}
                      inputValue={values.country}
                      getOptionLabel={(option) =>
                        option.text ? option.text : " "
                      }
                      fullWidth
                      isOptionEqualToValue={(option, value) => {
                        return option.text === value.text;
                      }}
                      onChange={(_, valueCountry) => {
                        setFieldValue("country", valueCountry?.text);
                        setFieldValue("city", "");
                        setFieldValue("streetAddress", "");
                        setFieldValue("postalCode", "");
                        setFieldValue("state", "");
                        getStatesforCountries(data, valueCountry?.text);
                      }}
                      // onOpen={() => {
                      //   setFieldValue("country", "");
                      // }}
                      // onOpen={() => {
                      //   setFieldValue("country", "");
                      // }}

                      renderInput={(params) => {
                        return (
                          <TextField
                            className="formAttribute"
                            type={"text"}
                            {...params}
                            label={"Select Country"}
                            {...formik.getFieldProps("country")}
                            error={
                              formik.touched.state &&
                              Boolean(formik.errors.country)
                            }
                            helperText={
                              formik.touched.country && formik.errors.country
                            }
                          ></TextField>
                        );
                      }}
                    ></Autocomplete>
                    <Autocomplete
                      id="combo-box-state"
                      options={states}
                      getOptionLabel={(option) =>
                        option.text ? option.text : ""
                      }
                      disableClearable
                      fullWidth
                      limitTags={5}
                      inputValue={values.state}
                      onChange={(_, valueState) => {
                        setFieldValue("state", valueState?.text);
                        setFieldValue("city", "");
                        setFieldValue("streetAddress", "");
                        setFieldValue("postalCode", "");
                      }}
                      isOptionEqualToValue={(option, value) =>
                        option.id === value.id
                      }
                      renderInput={(params) => {
                        return (
                          <TextField
                            value={values?.state}
                            className="formAttribute"
                            type={"text"}
                            {...params}
                            label={"Select State"}
                            {...formik.getFieldProps("state")}
                            error={
                              formik.touched.state &&
                              Boolean(formik.errors.state)
                            }
                            helperText={
                              formik.touched.state && formik.errors.state
                            }
                          ></TextField>
                        );
                      }}
                    ></Autocomplete>

                    <TextField
                      autoComplete="city"
                      type={"text"}
                      label={"City"}
                      className="formAttribute"
                      {...formik.getFieldProps("city")}
                      error={formik.touched.city && Boolean(formik.errors.city)}
                      helperText={formik.touched.city && formik.errors.city}
                    ></TextField>

                    <TextField
                      autoComplete="streetAddress"
                      type={"text"}
                      className="formAttribute"
                      label={"Street Address:(no PO Boxes)"}
                      {...formik.getFieldProps("streetAddress")}
                      error={
                        formik.touched.streetAddress &&
                        Boolean(formik.errors.streetAddress)
                      }
                      helperText={
                        formik.touched.streetAddress &&
                        formik.errors.streetAddress
                      }
                    ></TextField>

                    <TextField
                      className="formAttribute"
                      autoComplete="postalCode"
                      type={"text"}
                      label={"Zip or Postal Code"}
                      {...formik.getFieldProps("postalCode")}
                      error={
                        formik.touched.postalCode &&
                        Boolean(formik.errors.postalCode)
                      }
                      helperText={
                        formik.touched.postalCode && formik.errors.postalCode
                      }
                    ></TextField>
                  </Stack>
                </Stack>
                <Stack marginTop={"10px"} gap={"10px"}>
                  <TextField
                    autoComplete="sdsName"
                    type={"text"}
                    label={"Please type the name of the SDS you need"}
                    multiline
                    minRows={4}
                    variant="outlined"
                    {...formik.getFieldProps("sdsName")}
                    error={
                      formik.touched.sdsName && Boolean(formik.errors.sdsName)
                    }
                    helperText={formik.touched.sdsName && formik.errors.sdsName}
                  ></TextField>
                  <TextField
                    autoComplete="otherComments"
                    type={"text"}
                    label={"Other Comments"}
                    multiline
                    minRows={4}
                    variant="outlined"
                    {...formik.getFieldProps("otherComments")}
                    error={
                      formik.touched.otherComments &&
                      Boolean(formik.errors.otherComments)
                    }
                    helperText={
                      formik.touched.otherComments &&
                      formik.errors.otherComments
                    }
                  ></TextField>
                </Stack>
                <div className="sdsSubmitbutton">
                  <button
                    type="submit"
                    className="sdsbutton  "
                    // onClick={handleSearchClick}
                  >
                    Submit
                  </button>
                </div>

                {openSuccessModel && (
                  <SuccessModal
                    setOpenSuccessModel={setOpenSuccessModel}
                    SuccessTitle="Success"
                    Successmessage={"Your request has been submitted."}
                    successCallback={gobacktoHome}
                  />
                )}
                {openErrorModel && (
                  <ErrorModal
                    setOpenErrorModel={setOpenErrorModel}
                    ErrorTitle={"Errors"}
                    Errormessage={errorMessage}
                    closeCallback={poppupCloseCallBack}
                  />
                )}
              </Form>
            </FormikProvider>
          </div>
        </div>
      )}
    </div>
  );
}

const SubmitButton = styled.div`
  padding: 10px 0px;
  align-items: center;
  justify-content: end;
  display: flex;
`;
const Error = styled.div`
  width: "100%";
`;
export default SDSRequestFrom;
