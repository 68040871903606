import React, { useState } from "react";
import "./Menu.css";
import { Link } from "react-router-dom";
export default function SDSMenuLink({ menuObj }) {
  const [openMenu, setOpenMenu] = useState(false);

  const onMouseO = () => {
    setOpenMenu(true);
  };

  const onMouseL = () => {
    setOpenMenu(false);
  };

  return (
    <li
      className={openMenu ? "nav-primary__item is-open" : "nav-primary__item "}
      onMouseEnter={onMouseO}
      onMouseLeave={onMouseL}
      onClick={(event) => {
        event.stopPropagation();
        event.preventDefault();
      }}
    >
      <a
        href="#"
        // href="javascript:return false;"
        className={
          "menu-item menu-item-type-custom menu-item-object-custom menu-item-has-children"
        }
        data-nav="true"
      >
        {menuObj.name}
      </a>
      <ul
        className={"nav-secondary"}
        onMouseOver={onMouseO}
        onMouseLeave={onMouseL}
      >
        {menuObj.children &&
          menuObj.children.map((item, index) => {
            return (
              <li
                key={index + 1}
                className={"nav-secondary__item"}
                onClick={() => {
                  onMouseL();
                }}
              >
                <Link
                  to={item.to}
                  className="menu-item menu-item-type-post_type menu-item-object-page "
                >
                  {item.name}
                </Link>
              </li>
            );
          })}
      </ul>
    </li>
  );
}
