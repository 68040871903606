import React, { useState } from "react";
import "./CustomerSearch.css";
import "./OptIn.css";
import { Typography } from "@mui/material";
import CustomerDetails from "./CustomerDetails";

export default function CustomerSearch(props) {
  const [isShown, setIsShown] = useState(true);
  const [customerCode, setCustomerCode] = useState("");

  const handleButtonClick = () => {
    setIsShown(false);
  };
  return (
    <div>
      {isShown ? (
        <div>
          <div id="Search">
            <Typography>
              Please enter a customer code as shown on your company's Safety
              Data Sheet cover letter.
            </Typography>

            <div className="searchContainer">
              <img
                alt="Required"
                src={process.env.PUBLIC_URL + "images/exclam.gif"}
              />
              <label id="lblCustomerCode" className="FormLabel">
                Customer Code:
              </label>
              <input
                type="text"
                id="txtCustomerCode"
                placeholder="Customer code"
                className="sdssearch-optin-input"
                onChange={(e) => setCustomerCode(e.target.value)}
              ></input>

              <button
                id="btnSubmit"
                className="sdsbutton sdsbutton-optin-search "
                onClick={handleButtonClick}
              >
                Find
              </button>
            </div>
          </div>
        </div>
      ) : (
        <CustomerDetails
          setIsShown={setIsShown}
          customerCode={customerCode}
          setCustomerCode={setCustomerCode}
        ></CustomerDetails>
      )}
    </div>
  );
}
