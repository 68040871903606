import React from "react";
import "./SDSTitle.css";

export default function SDSTitle() {
  return (
    <div className="content_wrap">
      <div className="section_title">
        <div className="titlewrapper">
          <h2>Georgia-Pacific Safety Data Sheets(SDS)</h2>
        </div>
      </div>
    </div>
  );
}
