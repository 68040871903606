import React from "react";
import ReactDOM from "react-dom";
import { Formik, Field, Form, ErrorMessage, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import DeleteIcon from "@mui/icons-material/Delete";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Table from "react-bootstrap/Table";
import styled from "styled-components";
const phoneReg = "^[1-9][0-9]*$";
const initialValues = {
  Emails: [
    {
      Email: "",
      FirstName: "",
      LastName: "",
      PhoneNumber: "",
    },
  ],
};
export default function EditableTable1(props) {
  //const phoneRegex = "^[1-9!-/:-@[-`{-~][0-9!-/:-@[-`{-~]*$";

  const StyledTextBox = styled(TextField)`
    & .MuiInputBase-input {
      height: 0.1rem;
      width: 100%;
    }
  `;
  return (
    <div>
      <Formik
        initialValues={initialValues}
        onSubmit={async (values, { resetForm }) => {
          props.UpdateOptin(true, values.Emails);
          resetForm({ values: initialValues });
        }}
        validationSchema={Yup.object({
          Emails: Yup.array().of(
            Yup.object({
              FirstName: Yup.string().required("First name required"),
              LastName: Yup.string().required("Last name required"),
              Email: Yup.string()
                .email("Invalid Email")
                .required("Email required"),
              PhoneNumber: Yup.string()
                .required("Phone number required")
                .max(
                  20,
                  "Telephone field shall have maximum of 20 digits and should not start with 0."
                ),
            })
          ),
        })}
      >
        {({
          values,
          touched,
          errors,
          handleChange,
          handleBlur,
          isValid,
          setValues,
        }) => (
          <Form>
            <FieldArray name="Emails">
              {({ insert, remove, push }) => (
                <div className="emailtable">
                  <button
                    type="button"
                    className="sdsbutton  fa-lg sdsbutton-optin-search  btnmargin"
                    onClick={() =>
                      push({
                        FirstName: "",
                        Email: "",
                        LastName: "",
                        PhoneNumber: "",
                      })
                    }
                  >
                    {props.AddEmailName}
                  </button>
                  {values.Emails.length > 0 && (
                    <button
                      type="submit"
                      className="sdsbutton  fa-lg sdsbutton-optin-search  btnmargin"
                    >
                      Submit
                    </button>
                  )}
                  {values.Emails.length > 0 && (
                    <Row className="optinHeader">
                      <Col className="optinHeader ">EMAIL</Col>
                      <Col className="optinHeader ">FIRST NAME</Col>
                      <Col className="optinHeader ">LAST NAME</Col>
                      <Col className="optinHeader ">PHONE NUMBER</Col>
                      <Col align="end" className="optinHeader "></Col>
                    </Row>
                  )}

                  {values.Emails.length > 0 &&
                    values.Emails.map((friend, index) => {
                      const FirstName = `friend[${index}].FirstName`;
                      const touchedFirstName = getIn(touched, FirstName);
                      const errorFirstName = getIn(errors, FirstName);

                      const LastName = `friend[${index}].LastName`;
                      const touchedLastName = getIn(touched, LastName);
                      const errorLastName = getIn(errors, LastName);

                      const Email = `friend[${index}].Email`;
                      const touchedEmail = getIn(touched, Email);
                      const errorEmail = getIn(errors, Email);

                      const PhoneNumber = `friend[${index}].PhoneNumber`;
                      const touchedPhoneNumber = getIn(touched, PhoneNumber);
                      const errorPhoneNumber = getIn(errors, PhoneNumber);
                      return (
                        <Row className=" optinTableRow" key={index}>
                          <Col>
                            <StyledTextBox
                              name={`Emails.${index}.Email`}
                              type="text"
                              helperText={
                                touchedEmail && errorEmail ? errorEmail : ""
                              }
                              error={Boolean(touchedEmail && errorEmail)}
                              onChange={handleChange}
                              placeholder="Email"
                              InputLabelProps={{ shrink: false }}
                              sx={{
                                "& label.Mui-focused": {
                                  display: "none",
                                },
                                "& legend": {
                                  display: "none",
                                },
                              }}
                            />
                            <ErrorMessage
                              name={`Emails.${index}.Email`}
                              component="div"
                              className="validation-error-message"
                            />
                          </Col>
                          <Col>
                            <StyledTextBox
                              name={`Emails.${index}.FirstName`}
                              placeholder="First Name"
                              type="text"
                              helperText={
                                touchedFirstName && errorFirstName
                                  ? errorFirstName
                                  : ""
                              }
                              error={Boolean(
                                touchedFirstName && errorFirstName
                              )}
                              onChange={handleChange}
                              sx={{
                                "& label.Mui-focused": {
                                  display: "none",
                                },
                                "& legend": {
                                  display: "none",
                                },
                              }}
                            />
                            <ErrorMessage
                              name={`Emails.${index}.FirstName`}
                              component="div"
                              className="validation-error-message"
                            />
                          </Col>
                          <Col>
                            <StyledTextBox
                              name={`Emails.${index}.LastName`}
                              placeholder="Last Name"
                              type="text"
                              helperText={
                                touchedLastName && errorLastName
                                  ? errorLastName
                                  : ""
                              }
                              error={Boolean(touchedLastName && errorLastName)}
                              onChange={handleChange}
                              sx={{
                                "& label.Mui-focused": {
                                  display: "none",
                                },
                                "& legend": {
                                  display: "none",
                                },
                              }}
                            />
                            <ErrorMessage
                              name={`Emails.${index}.LastName`}
                              component="div"
                              className="validation-error-message"
                            />
                          </Col>
                          <Col>
                            <StyledTextBox
                              name={`Emails.${index}.PhoneNumber`}
                              placeholder="Phone Number"
                              type="text"
                              helperText={
                                touchedPhoneNumber && errorPhoneNumber
                                  ? errorPhoneNumber
                                  : ""
                              }
                              error={Boolean(
                                touchedPhoneNumber && errorPhoneNumber
                              )}
                              onChange={handleChange}
                              sx={{
                                "& label.Mui-focused": {
                                  display: "none",
                                },
                                "& legend": {
                                  display: "none",
                                },
                              }}
                            />
                            <ErrorMessage
                              name={`Emails.${index}.PhoneNumber`}
                              component="div"
                              className="validation-error-message"
                            />
                          </Col>
                          <Col>
                            <Button
                              startIcon={<DeleteIcon />}
                              onClick={() => remove(index)}
                              className="mr10 "
                            >
                              Delete
                            </Button>
                          </Col>
                        </Row>
                      );
                    })}
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
}
