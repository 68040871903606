import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import "./Modal.css";

function SuccessModal({
  setOpenSuccessModel,
  Successmessage,
  SuccessTitle,
  successCallback,
}) {
  const handleClose = () => {
    setOpenSuccessModel(false);
    if (successCallback) successCallback();
  };

  return (
    <div>
      <Dialog open={true} fullWidth maxWidth="md">
        <DialogTitle id="dialog-title-success" className="successtitle">
          {SuccessTitle}
        </DialogTitle>
        {/* <CheckCircleIcon></CheckCircleIcon> */}
        <DialogContent>{Successmessage}</DialogContent>
        <DialogActions>
          <button
            className="sdsbutton  "
            variant="contained"
            onClick={handleClose}
            autoFocus
          >
            Close
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

SuccessModal.defaultProps = {
  SuccessTitle: "Request has been submitted succesfully",
  Successmessage: "Success message",
};
export default SuccessModal;
