import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
const baseURL = process.env.REACT_APP_DIRECT_DOWNLOAD_BASEURL;

function DirectDownload() {
  let [queryParameters] = useSearchParams();
  let msdsid = queryParameters.get("msdsid");
  let LangCode = queryParameters.get("LangCode");
  const [URL, setURL] = useState("");

  useEffect(() => {
    const lU = `${baseURL}${msdsid}&LangCode=${LangCode}`;
    setURL(lU);
  }, [msdsid, LangCode, URL]);
  return (
    <>
      {URL && (
        <iframe
          src={URL}
          style={{
            width: "100vw",
            height: "100vh",
            zoom: "100%",
            scale: "1",
          }}
        />
      )}
    </>
  );
}

export default DirectDownload;
