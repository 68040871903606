import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Activation.css";
import { useSearchParams } from "react-router-dom";
export default function Activation() {
  const baseAPIURL = process.env.REACT_APP_API_BASEURL;
  let baseURL = `${baseAPIURL}Activation/Activate?`;
  const [searchParams, setSearchParams] = useSearchParams();
  const [message, setMessage] = useState("");
  const currEnv = process.env.REACT_APP_ENVIRONMENT;

  useEffect(() => {
    const abortController = new AbortController();

    axios({
      method: "GET",
      url:
        baseURL +
        "ActivationCode=" +
        searchParams.get("ActivationCode") +
        "&expire=" +
        searchParams.get("expire"),
      withCredentials: false,
      timeout: 30000,
    })
      .then((res) => {
        setMessage(res.data.message);
      })
      .catch((error) => {
        setMessage(error.response.data.detail);
      });
  });
  return (
    <div>
      {message != "" && (
        <div className="outerWrapper">
          <div className="margin">
            <p>{message}</p>
          </div>
        </div>
      )}
    </div>
  );
}
